<template>
  <div class="integralExchangeConfirm">
    <Header :hideGoBack="false" :title="title" theme="primary" />
    <div class="container__main">
      <div class="wrap__info">
        <div class="box__money">
          <div class="img">
            <img src="../../assets/images/public/ic_redeem_img.webp" alt="" />
          </div>
          <div class="money">{{ usersData["integral"] }}</div>
        </div>
        <div class="box__text">
          可用积分
        </div>
      </div>
      <div class="wrap__content">
        <div class="box__money">
          <div class="img">
            <img src="../../assets/images/public/ic_redeem_current.webp" alt="" />
          </div>
          <div class="money">面值 : ¥ {{ cardQuota }}</div>
        </div>
        <div class="box__money">
          <div class="img">
            <img src="../../assets/images/public/ic_redeem_gift_package.webp" alt="" />
          </div>
          <div class="money">兑换价格 : {{ price }}积分</div>
        </div>
      </div>
      <div class="wrap__btn">
        <div class="box__btn" @click="postMoney()">
          兌換
        </div>
      </div>
    </div>
    <FooterMenu />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "integralExchange",
  components: {
    Header,
    FooterMenu
  },
  data() {
    return {
      title: "积分兑换",
      data: [],
      cardQuota: 0,
      price: 0,
      id: null
    };
  },
  computed: {
    ...mapState("products", ["productsIdData"]),
    ...mapState("users", ["usersData"])
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    ...mapActions("products", ["postProductsId"]),
    ...mapActions("visitorUnauthorized", ["changeVisitorUnauthorized"]),
    init() {
      this.cardQuota = this.$route.query.cardQuota;
      this.price = this.$route.query.price;
      this.id = this.$route.query.id;
    },
    postMoney() {
      this.postProductsId(this.id).then((res) => {
        if (res.code == 'VisitorUnauthorized') {
          this.changeVisitorUnauthorized(true)
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.integralExchangeConfirm {
  width: 100%;

  .container__main {
    height: calc(92vh - 72px);
    overflow-y: scroll;

    .wrap__info {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-color: var(--orange);
      padding-top: 1rem;
      padding-bottom: 1rem;

      .box__money {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        .img {
          width: 1.5rem;
          margin-right: 0.5rem;
          font-size: 0;

          img {
            width: 100%;
          }
        }

        .money {
          color: var(--white);
          font-size: 2rem;
        }
      }

      .box__text {
        color: var(--orange);
        background-color: var(--white);
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        border-radius: 20px;
      }
    }

    .wrap__content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      .box__money {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        .img {
          width: 1.1rem;
          font-size: 0;
          margin-right: 0.5rem;

          img {
            width: 100%;
          }
        }

        .money {
          font-size: 0.9rem;
          color: var(--gray);
        }
      }
    }

    .wrap__btn {
      width: 100%;
      display: flex;
      justify-content: center;

      .box__btn {
        background-color: var(--orange);
        color: aliceblue;
        padding: 0.8rem 0;
        width: 80%;
        border-radius: 20px;
      }
    }
  }
}
</style>
